<template>
  <div class="modal">
    <transition name="fade">
      <div v-show="visible" class="modal-back" />
    </transition>
    <transition name="scale">
      <div v-show="visible" class="modal-content">
        <div @click="handleClose" class="close" />
        <video :poster="poster" ref="video" class="video" :src="src"></video>
        <div @selectstart="forbid" class="video-controls">
          <img
            @click="pause"
            class="pause"
            v-if="isPlay"
            src="@/assets/images/audio/pause.png"
            alt=""
          />
          <img
            @click="play"
            class="play"
            v-else
            src="@/assets/images/audio/play.png"
            alt=""
          />
          <span class="percent-text"
            >{{ fixed(currentTime) }}/{{ fixed(duration) }}</span
          >
          <Slider
            class="percent-line"
            :value="percent"
            @change="processChange"
          />
          <img
            @click="toggleVolume"
            class="volume"
            :draggable="false"
            src="@/assets/images/audio/volume.png"
            alt=""
          />
          <img
            class="full"
            :draggable="false"
            @click="fullScreen"
            src="@/assets/images/audio/full.png"
            alt=""
          />
          <VoiceSlider
            v-if="volumeVisible"
            @change="volumeChange"
            @finish="toggleVolume"
            :percent="volume"
            class="slider"
          />
        </div>
<!--        <div class="video-content">-->
<!--          <h3 class="video-content-title">{{ title }}</h3>-->
<!--          <p class="video-content-desc">{{ desc }}</p>-->
<!--        </div>-->
      </div>
    </transition>
  </div>
</template>
<script>
import { Slider } from 'ant-design-vue'
import VoiceSlider from './slider'
export default {
  name: 'common-video',
  components: {
    VoiceSlider,
    Slider
  },
  props: {
    poster: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      video: null,
      isPlay: false,
      volumeVisible: false,
      currentTime: 0,
      duration: 0,
      percent: 0,
      volume: 50,
    }
  },
  methods: {
    fixed (text) {
      return text.toFixed(1)
    },
    forbid (e) {
      e.preventDefault()
    },
    play () {
      this.video.play()
    },
    pause () {
      this.video.pause()
      this.isPlay = false
    },
    playing () {
      this.isPlay = true
    },
    timeupdate () {
      this.currentTime = this.video.currentTime
      this.percent = this.currentTime * 100 / this.duration
    },
    ended () {
      this.isPlay = false
      this.percent = 0
      this.currentTime = 0
    },
    loadedMetaData () {
      this.duration = this.video.duration
    },
    toggleVolume () {
      this.volumeVisible = !this.volumeVisible
    },
    volumeChange (value) {
      this.volume = value
      this.video.volume = value / 100
    },
    processChange (value) {
      this.video.currentTime = value / 100 * this.duration
    },
    fullScreen () {
      this.video.requestFullscreen()
    },
    handleClose () {
      this.pause()
      this.$emit('close')
    },
    initVideo () {
      this.video = this.$refs.video
      this.video.volume = this.volume / 100
      this.video.addEventListener('playing', this.playing)
      this.video.addEventListener('timeupdate', this.timeupdate)
      this.video.addEventListener('ended', this.ended)
      this.video.addEventListener('loadedmetadata', this.loadedMetaData)
    }

  },
  mounted () {
    this.initVideo()
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s linear;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.scale-enter-active,
.scale-leave-active {
  transition: all 0.3s linear;
}
.scale-enter,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
.modal {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .modal-back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.7);
  }
  .modal-content {
    position: absolute;
    top: 0.9rem;
    left: 50%;
    margin-left: -4.8rem;
    width: 9.6rem;
    height: 6rem;
    background: #ffffff;
    .close {
      width: 0.52rem;
      height: 0.52rem;
      background: url("../../../assets/images/audio/close.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      right: -0.52rem;
      cursor: pointer;
    }
    .video {
      outline: none;
      width: 100%;
      height: 6rem;
      overflow: hidden;
      display: block;
      background: black;
    }
    .video-controls {
      display: flex;
      box-sizing: border-box;
      height: 0.6rem;
      align-items: center;
      padding: 0 0.28rem 0 0.32rem;
      background: #000000;
      position: absolute;
      bottom: 0;
      width: 100%;
      opacity: 0.8;
      .play {
        display: block;
        width: 0.22rem;
        height: 0.22rem;
      }
      .pause {
        display: block;
        width: 0.14rem;
        margin-right: 0.08rem;
      }
      .percent-text {
        display: block;
        margin-left: 0.3rem;
        color: #e9e3e3;
        font-size: 0.14rem;
        width: 2.1rem;
        overflow: hidden;
        transform: scale(0.9);
        font-weight: 400;
        @media screen and (max-width: 900px) {
          margin-left: 0rem;
          width: 2.5rem;
          transform: scale(0.5);
        }
      }
      .percent-line {
        display: block;
        margin-left: 0.2rem;
        height: 0.04rem;
        margin-right: 0.58rem;
        width: 100%;
      }
      .volume {
        width: 0.22rem;
        height: 0.22rem;
      }
      .full {
        width: 0.22rem;
        height: 0.22rem;
        margin-left: 0.4rem;
      }
      .slider {
        position: absolute;
        right: 0.98rem;
        top: -0.95rem;
        width: 0.06rem;
        height: 1rem;
      }
    }
    .video-content {
      padding-top: 0.1rem;
      .video-content-title {
        margin: 0;
        font-weight: 400;
        color: #ffffff;
      }
      .video-content-desc {
        color: #c8c8c8;
        font-size: 0.16rem;
        height: 1.5rem;
        overflow-y: scroll;
      }
    }
  }
}
</style>
