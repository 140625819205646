<template>
  <div v-if="isShow" class="bottom-nodata-box">
<!--    <img class="nodata" src="/images/common/no-data.png" alt="" />-->
    <img :style="{width: 1.13 + 'rem',eight: 1.13 + 'rem'}" src="/images/home/empty-common.png" alt="">
    <p>建设中</p>
  </div>
</template>
<script>
import { ref, watchEffect, onBeforeUnmount } from 'vue'
export default {
  name: '',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const isShow = ref(false)
    let timer = null
    watchEffect(() => {
      if (props.visible) {
        timer = setTimeout(() => isShow.value = true, 500)
      } else {
        clearTimeout(timer)
        isShow.value = false
      }
    })
    onBeforeUnmount(() => {
      clearTimeout(timer)
    })
    return {
      isShow
    }
  }

}
</script>
<style lang="scss" scoped>
.nodata {
  display: block;
  width: 2.5rem;
}
.bottom-nodata-box {
  display: flex;
  margin: 2rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #B8B8B8;
    font-size: 0.18rem;
    font-weight: 400;
  }
}

</style>
