import { ref, watch, reactive } from 'vue'

export function useParams (data) {
  let params = reactive({
    pageNum: 1,
    pageSize: 10,
    ...data
  })
  const search = (data) => {
    for (let [key, val] of Object.entries(data)) {
      params[key] = val
    }
  }
  return {
    params,
    search
  }
}

export function useList (query, api, filter) {
  const list = ref([])
  const total = ref(0)
  const pageNum = ref(1)
  const getList = async () => {
    let res = await api(query)
    list.value = res?.records ?? []
    if (filter) {
      list.value = filter(list.value)
    }
    total.value = res?.total ?? 0
    if (query.pageNum) {
      pageNum.value = Number(query.pageNum)
    }

  }
  getList()
  watch(query, getList)

  return {
    total,
    pageNum,
    list,
    getList
  }
}