<template>
  <div class="pagination">
    <a-pagination
      :current="current"
      :total="total"
      :pageSize="size"
      show-less-items
      @change="change"
    />
  </div>
</template>
<script>
export default {
  name: 'common-pagination',
  components: {},
  props: {
    total: {
      type: Number,
      default: 10
    },
    current: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 10
    },
  },
  emits: ['pageChange'],
  data () {
    return {
    }
  },
  methods: {
    change (page) {
      this.$emit('pageChange', page)
    }
  }
}
</script>
<style lang="scss">
.pagination {
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    background: #f0f0f0;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
    color: #666666;
    background: #f0f0f0;
  }
  .ant-pagination-item {
    background: #f0f0f0;
    border: none;
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border: none;
  }
  .ant-pagination-item-active {
    background: #01398D;
    border: none;
  }
  .ant-pagination-item a {
    color: #666666;
  }
  .ant-pagination-item-active a {
    color: #fff;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #666666;
  }
}
</style>
