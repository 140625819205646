<template>
  <div class="wrap">
    <header class="header">
      <img class="header-title" src="/images/interview/title.png" alt="" />
    </header>
    <div class="d-f-c">
      <div class="content">
        <h3 class="content-title">小镇访谈录</h3>
        <ul class="content-list">
          <li
            v-for="item in list"
            :key="item.id"
            class="list-item"
            @click="goPage('/interviewdetail?id=' + item.id)"
          >
            <div class="item">
                <img :src="item.picture" class="cover-img"/>
                <h3 class="title">{{item.title}}</h3>

              <h3 class="item-content">{{getText(item.content)}}</h3>
            </div>

          </li>
        </ul>
        <NoData :visible="!list.length" />
        <div class="page">
          <Page
            class="page-tools"
            :size="params.pageSize"
            :current="pageNum"
            :total="total"
            @pageChange="pageChange"
          />
        </div>
      </div>
    </div>
    <Video
      @close="toggleVisible"
      :visible="visible"
      :src="active?.video"
      :title="active?.title"
      :desc="active?.content"
      :poster="active?.picture"
    />
  </div>
</template>
<script>
import NoData from '@/components/common/nodata'
import { ref, reactive } from 'vue'
import Video from '@/components/common/video/video'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import Page from '@/components/common/pagination'
import { useList, useParams } from '@/hooks/useList'
import api from '@/api'
export default {
  name: 'interview',
  components: {
    Page,
    Video,
    NoData
  },
  setup () {
    const visible = ref(false)
    const active = reactive({})
    const route = useRoute()
    const { params, search, } = useParams({
      pageNum: route.query?.pn ?? 1,
      pageSize: 8,
      tabs:'TALK'
    })
    const { total, pageNum, list, getList } = useList(params, api.townStory)
    const toggleVisible = () => {
      visible.value = !visible.value
    }
    const setActive = data => {
      for (let [key, value] of Object.entries(data)) {
        active[key] = value
      }
    }

    onBeforeRouteUpdate((to) => {
      search({
        pageNum: to.query?.pn
      })
    })
    const getText = (str) =>{
      let words = str.replace(/<[^<>]+>/g,'').replace(/&nbsp;/,'')
      return words
    }

    return {
      total,
      active,
      visible,
      pageNum,
      params,
      list,
      getList,
      search,
      toggleVisible,
      setActive,
      getText
    }

  },
  methods: {
    pageChange (page) {
      this.$router.push({
        query: {
          pn: page
        }
      })
    },
    goPage (url) {
      this.$router.push(url)
    },
    showDetail (data) {
      this.setActive(data)
      this.toggleVisible()
    }
  }

}
</script>
<style lang="scss" scoped>
.wrap {
  min-height: calc(100vh - 1.06rem);
  //background: url("/images/common/bg.png");
  background: #ffffff;
  .header {
    height: 7.36rem;
    width: 100%;
    background: url("/images/interview/banner.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    .header-title {
      margin-top: 2.88rem;
      width: 6.4rem;
      height: 1.6rem;
      object-fit: cover;
    }
  }
  .content {
    width: 12rem;
    padding: 0.6rem 0;
    min-height: calc(100vh - 5.6rem);
    .content-title {
      font-size: 0.3rem;
      font-weight: bold;
      color: #333333;
    }
    .content-subTitle {
      font-size: 0.14rem;
      font-weight: normal;
      color: #999999;
    }
    .content-list {
      display: flex;
      position: relative;
      margin-top: 0.35rem;
      flex-wrap: wrap;
      .list-item {
        position: relative;

        margin-bottom: 0.8rem;
        transition: all 300ms;
        &:not(:nth-of-type(4n)) {
          margin-right: 0.16rem;
        }
      }
    }
  }
  .item {
    width: 2.83rem;
    .cover-img {
      width: 2.83rem;
      height: 3.21rem;
    }
    .title {
      font-size: 0.18rem;
      margin-top: 0.26rem;
      margin-bottom: 0.1rem;
      @include text-moreLine(1)
    }
    .item-content {
      font-size: 0.14rem;
      color: #777777;
      @include text-moreLine(3)
    }
  }
  .page {
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
  }
}
</style>
