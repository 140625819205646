<template>
  <div ref="slider" class="slider">
    <div :style="`height: ${percent}%`" ref="process" class="process">
      <div ref="dot" class="dot" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'common-slider',
  props: {
    percent: {
      type: Number,
      default: 0
    }
  },
  methods: {
    initListener () {
      let endY = 0
      const that = this
      let value = 0
      const sliderHeight = this.$refs.slider.clientHeight
      let startY = this.$refs.slider.getBoundingClientRect().bottom
      const handleMouseMove = function (e) {
        endY = e.clientY
        if (endY >= startY) {
          value = 0
        } else if (startY - endY >= sliderHeight) {
          value = 100
        } else {
          value = (startY - endY) * 100 / sliderHeight
        }
        that.$emit('change', value)

      }
      const handleMouseDown = function () {
        window.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('mouseup', handleMouseUp)
      }
      const handleMouseUp = function () {
        window.removeEventListener('mousemove', handleMouseMove)
        that.$emit('finish')
      }
      this.$refs.dot.addEventListener('mousedown', handleMouseDown)
    }
  },
  mounted () {
    this.initListener()
  }
}
</script>
<style lang="scss" scoped>
.slider {
  transform: rotate(180deg);
  height: 100%;
  background: white;
  border-radius: 10%;
  z-index: 10000;
  .process {
    position: relative;
    background: #ae9a79;
    .dot {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      position: absolute;
      width: 250%;
      padding-top: 100%;
      background: #ffffff;
    }
  }
}
</style>
